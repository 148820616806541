<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/imgs/home-sanybeach.jpg')"
      class="white--text"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading :title="$t('title') + ((location) ? $t('pre') + location : '')" />
          <base-body class="hidden-xs-only">
            {{ $t("subtitle") }}
          </base-body>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },
    data () {
      return {
        location: '',
        locale: '',
      }
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"subtitle": "Find out how we guarantee the safety and transparency of the sanitization interventions of sunbeds and sun umbrellas in the establishments involved. We put the safety of bathers first, allowing them to enjoy the beach in complete tranquility.",
		"title": "Holidays in total safety",
    "pre": " in "
	},
	"it": {
		"subtitle": "Scopri come garantiamo la sicurezza e la trasparenza degli interventi di sanificazione di lettini e ombrelloni negli stabilimenti coinvolti. Poniamo la sicurezza dei bagnanti al primo posto, permettendogli di godersi la spiaggia in piena tranquillità.",
		"title": "Vacanze in totale sicurezza",
    "pre": " a "
	}
}
</i18n>
